<template>
  <div class="about my-page-wrap">
    <div class="inner" style="position:relative;">
      <div class="content" style="justify-content:center;">
        <div class="left">
          <img src="~@/assets/imgs/left.png" alt="" />
        </div>
        <div>
          <div class="main-content">
            <div class="center">
              <div class="left_inner">
                <div class="ab_title">
                  <div class="title_1">SINCE 2017</div>
                  <div class="title_2">广西燕台山酒庄有限公司</div>
                  <div class="title_3">GUANGXI YANTAISHAN INDUSTRY CO., LTD.</div>
                </div>
                <div class="ab_data">
                  <ul>
                    <li>
                      <p class="num"><span class="animateNum" data-animatetype="num" data-animatetarget="2008">2017</span><em></em></p>
                      <p class="tit">成立于2017年</p>
                    </li>
                    <li>
                      <p class="num"><span class="animateNum">500万</span><em></em></p>
                      <p class="tit">注册资本500万元</p>
                    </li>
                    <li>
                      <p class="num"><span class="animateNum">北京</span><em></em></p>
                      <p class="tit">总部北京</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="right_inner">
                <div class="arccontent">
                  <!--<p class="text tajust">广西燕台山酒庄有限公司成立于2017年， 燕台山酒庄位于"中国长寿之乡”一广西南宁马山县周鹿镇大北屯，注册资本500万元，是集酿酒、藏酒、加工、宴会、 住宿、养生于一体的生态酒庄。这里山清水秀阳光充足，雨量充沛，冬暖夏凉，空气中富含大量的微生物 类群，非常有益于原浆酒的生产及洞藏。</p>
                  <p class="text tajust">燕台山酒庄酿造的蜜香型原浆酒，是以当地优质有机大米、天然山泉水为原料，利用得天独厚的自然环境，采用祖辈流传下来独特的传统工艺，精心酿制而成。酒中富含有氨基酸、低聚糖、有机酸和多种维生素，具有很高的营养价值，并且饮后不上头、喉咙不痛、对身体伤害程度比较小，是最健康的白酒。</p>
                  <p class="text">燕台山酒庄洞藏的原浆酒，全为纯粮酿造，天然洞藏，从酿造到装瓶出售，整个生产过程完全按照绿色"标准执行，粮食通过酒曲自然发酵，没有勾兑，不添加任何人工成分 (酒精、增香剂)。精选的原浆酒，通过天然洞藏，自然挥发酒液中的醇类物质，酒质绵软柔和，酒色幽雅纯净，酒香绵甜酵厚能消除疲劳、安神定气、美颜健体，让人回味悠长从口感、香气、酒质等各个方面都远胜于其它类型白酒。</p>
                  <p class="text">燕台山洞藏白酒产品不仅口感醇厚、香气浓郁，而且具有独特的米香风味和文化内涵，是中国传统文化的重要组成部分。酒庄一直秉承“品质至上、诚信经营”的理念，致力于为客户提供最优质的产品和服务。我们的产品不仅畅销国内市场，而且远销海外，深受广大消费者的喜爱和信赖。</p>
                  <p class="text">酒庄宗旨： 我们的酒庄宗旨是“传承白酒文化，创造品质生活”。我们致力于传承和弘扬中国传统白酒文化，以精湛的技艺和严格的质量控制，生产出高品质的白酒产品，为客户创造品质生活。</p>
                  <p class="text">酒庄秉承“品质至上、诚信经营”的理念，以客户需求为导向，不断创新和提升产品和服务质量，为客户提供最优质的产品和服务。我们的酒庄还致力于社会责任，为社会和谐发展做出应有贡献。</p>
                  <p class="text">目前，公司按年产3万吨白酒的生产规模“整体规划，分步实施”，规划建设独具壮族文化特色的美丽工厂，争创4A景区。公司将始终坚持高质量发展理念，以智能酿造、振兴桂酒为目标，推进“桂字号”品牌提升工程，推动桂酒向品牌化、品质化、规模化发展，打造成米香型白酒行业领军品牌。</p>-->
                  <div class="text tajust" v-html="detail.content"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="~@/assets/imgs/right.png" alt="" />
        </div>
      </div>
    </div>
    <!-- <div class="idea">
      <div class="main-content">
        <div class="idea_inner">
          <div class="left">
            <div class="text">智能酿酒<br />振兴桂酒</div>
          </div>
          <div class="right">
            <div class="tit">经营理念</div>
            <div class="note">
              <p>企业使命：<span>提升顾客饮酒体验，创造开心美好生活</span></p>
              <p>经营方针：控销量、增存量、抓质量、提效益</p>
              <p>人才理念：<span>德才兼备 身心健康</span></p>
              <p>核心价值观：树正气、有担当、可持续</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="paper">
      <div class="main-content">
        <div class="paper_inner">
          <div class="title">证书证件</div>
          <div class="paper_warp">
            <div   
              class="paper_card"   
              v-for="(card, index) in cards"   
              :key="index"  
            >  
            <img class="paper_img" :src="$baseRemote + card.pic_path" alt="燕台山酒庄证书" />  
            <div class="text">{{ card.pic_name }}</div>  
          </div>  
            <!-- <div class="paper_card">
              <img class="paper_img" src="~@/assets/imgs/ryzs1.jpg" alt="" />
              <div class="text">体验之旅</div>
            </div>
            <div class="paper_card">
              <img class="paper_img" src="~@/assets/imgs/ryzs2.jpg" alt="" />
              <div class="text">体验之旅</div>
            </div>
            <div class="paper_card">
              <img class="paper_img" src="~@/assets/imgs/ryzs3.jpg" alt="" />
              <div class="text">体验之旅</div>
            </div>
            <div class="paper_card">
              <img class="paper_img" src="~@/assets/imgs/ryzs4.jpg" alt="" />
              <div class="text">体验之旅</div>
            </div>
            <div class="paper_card">
              <img class="paper_img" src="~@/assets/imgs/ryzs5.jpg" alt="" />
              <div class="text">体验之旅</div>
            </div>
            <div class="paper_card">
              <img class="paper_img" src="~@/assets/imgs/ryzs6.jpg" alt="" />
              <div class="text">体验之旅</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import http from "@/utils/http"

  export default {
    data() {
      return {
        cards: [],
        detail: {}
      }
    },
    created() {   
      this.fetchCards();  
    },
    mounted() {
      this.getDetail()
    },
    methods: {
      getDetail(id) {
        http({
          url: "/api/help/info?id=4",
          data: {}
        }).then((res) => {
          this.detail = res.data
        })
      },
      async fetchCards() {
        http({
          url: "/api/album/page",
          data: { page: 1, page_size: 50}
        }).then((res) => {
          this.cards = res.data.list;
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .about {
    padding-top: 0.4rem;
    .center {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.4rem;
      .left_inner {
        width:30%;
        padding-right:2%;
        .ab_title {
          text-align: left;
          .title_1 {
            font-size: 40px;
            text-transform: uppercase;
            display: block;
            font-family: "din";
            opacity: 0.5;
          }
          .title_2 {
            font-size: 28px;
            display: block;
            font-weight: bold;
            margin-bottom: 0.1rem;
          }
          .title_3 {
            text-transform: uppercase;
            font-size: 20px;
            display: block;
            font-family: "din";
            opacity: 0.5;
          }
        }
        .ab_data {
          width: 90%;
          // border: 1px solid rgba(0, 0, 0, 0.1);
          border-bottom: none;
          align-self: flex-start;
          margin-top: 0.3rem;
          ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            height: 100%;
            li {
              width: 50%;
              box-sizing: border-box;
              padding: 0.35rem 0.15rem;
              text-align: center;
              color: #b50003;
              border: 1px solid rgba(0, 0, 0, 0.1);
              justify-content: center;
              .num {
                width: 100%;
                font-size: 48px;
                line-height: 1.2;
                font-family: din;
                vertical-align: middle;
              }
              .tit {
                font-size: 14px;
              }
            }
            li:nth-child(2n) {
              border-left: none;
            }
            li:nth-child(n + 3) {
              border-top: none;
            }
          }
        }
      }
      .right_inner {
        flex: 1;
        .arccontent {
          .text {
            margin-bottom: 0.15rem;
            text-align: left;
            font-family: 宋体;
            font-size: 19px;
            line-height: 32px;
            text-indent: 2em;
          }
          .tajust {
            text-align: justify;
          }
        }
      }
    }
    .idea {
      width: 100%;
      height: 4.3rem;
      background: url(../../assets/imgs/jc2.jpg) center no-repeat;
      background-size: cover;
      padding: 0.4rem 0;
      .idea_inner {
        width: 100%;
        .left {
          box-sizing: border-box;
          float: left;
          width: 30%;
          padding: 10% 0;
          background: url(../../assets/imgs/cbg.jpg) center no-repeat;
          background-size: cover;
          text-align: center;
          font-size: 28px;
          font-weight: bold;
          color: #fff;
        }
        .right {
          box-sizing: border-box;
          float: right;
          width: 65%;
          padding: 0.5rem;
          height: 3.5rem;
          background: rgba(255, 255, 255, 0.9);
          .tit {
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 0.15rem;
          }
          .note {
            line-height: 2;
            p {
              font-size: 20px;
              span {
              }
            }
          }
        }
      }
    }
    .paper {
      width: 100%;
      margin-top:-.1rem;
      padding: 0 0 .4rem;
      .paper_inner {
        .title {
          text-align: left;
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 0.2rem;
        }
        .paper_warp {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          margin:0 -1.5%;
          .paper_card {
            width: 30%;
            margin: 0 1.5% 1.5%;
            overflow: hidden;
            .paper_img {
              transition: all 0.3s linear;
            }
            .text {
              text-align: center;
              padding: 0.15rem;
              font-size: 16px;
              background: #f5f5f5;
              color: #444;
            }
            &:hover {
              .paper_img {
                transform: scale(1.06);
              }
              .text {
                color: #b50003;
              }
            }
          }
        }
      }
    }
  }
  .my-page-wrap .right,.my-page-wrap .left{
    overflow: hidden;
    position: absolute;
    top:0;
    img{
      max-height:100%;    
    }
    
  }
 .my-page-wrap .left{
      left:0;
    }
  .my-page-wrap .right{
      right:0;
    }
</style>
